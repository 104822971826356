// libs
import { tw } from 'lib/tw'
import useTranslation from 'next-translate/useTranslation'
import { useProviderLink } from '@nhost/react'
// icons
import { GoogleIcon } from 'ui/icons'
// hooks
import { useRedirectToUrl } from 'hooks'

// views
const Title = tw.p`text-sm font-medium text-gray-700`

const ButtonsWrapper = tw.div`mt-1 flex justify-center space-x-2`

const LinkButton = tw.a`
  flex w-full cursor-pointer justify-center rounded-md border
  border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50
`

export function AuthMethods(): JSX.Element {
  const { t } = useTranslation('common')

  const redirectTo = useRedirectToUrl()

  const { google } = useProviderLink({ redirectTo })

  return (
    <>
      <Title>{t('authMethodsTitle')}</Title>
      <ButtonsWrapper>
        <LinkButton href={google}>
          <GoogleIcon />
        </LinkButton>
      </ButtonsWrapper>
    </>
  )
}
