// libs
import { tw } from 'lib/tw'
// components
import { Link } from 'ui/Link'

// views
const LinkContainer = tw.div`my-6 flex justify-center lg:justify-end items-baseline`

const LinkText = tw.span`text-sm font-medium text-primary-100 hover:opacity-80`

const Text = tw(LinkText)`!text-black hover:opacity-100 cursor-default mr-1`

// types
interface RedirectLinkProps {
  href: string
  text: string
  linkText: string
}

export function RedirectLink({ href, text, linkText }: RedirectLinkProps): JSX.Element {
  return (
    <LinkContainer>
      <Text>{text}</Text>
      <Link href={href}>
        <LinkText>{linkText}</LinkText>
      </Link>
    </LinkContainer>
  )
}
