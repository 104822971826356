// libs
import { tw } from 'lib/tw'
import useTranslation from 'next-translate/useTranslation'
import { getServerSidePropsWithAuth } from '@lib/nhost/getServerSidePropsWithAuth'
// layout
import { AuthLayout } from 'layouts'
// components
import { Link } from 'ui/Link'
import { SignInForm } from 'forms'
import { AuthMethods, RedirectLink } from 'components/Login'

// views
const Divider = tw.div`
  flex text-sm text-gray-500 mt-6
  after:my-auto after:ml-2 after:h-px after:flex-1 after:bg-gray-300 after:content-['']
  before:my-auto before:mr-2 before:h-px before:flex-1 before:bg-gray-300 before:content-['']
`

const LinkText = tw.div`text-center mt-3 cursor-pointer text-sm text-primary-100 hover:opacity-80`

function SignInPage() {
  const { t } = useTranslation('sign-in')

  return (
    <AuthLayout text={t('title')}>
      <AuthMethods />
      <Divider>{t('divider')}</Divider>
      <SignInForm />
      <Link href="/sign-in/passwordless">
        <LinkText>{t('passwordlessLoginLink')}</LinkText>
      </Link>
      <RedirectLink href="/sign-up" text={t('question')} linkText={t('signUpLink')} />
    </AuthLayout>
  )
}

export const getServerSideProps = getServerSidePropsWithAuth({ anonymous: true })

export default SignInPage
